
// 生产环境配置
const appConfig = {

  APP_ID: 'womenideas',
  
  // debug 总开关
  debug: false,

  // [必填] 服务端口
  port: 4000,

  // [必填] 网站域名完整地址
  domainName: 'womenideas.cn',
  //domainName: 'localhost',

  // [必填] 网站名称
  name: 'Women Ideas',

  // [必填] 网站描述
  description: '2023 Women ideas 专注 想象 成长. All rights reserved.',

  //网站首次运行时间
  releaseDate: '2023.8.1',

  // [选填] 联系我们的邮箱地址
  contactEmail: 'zhulinmengxiao@womenideas.cn',

  // [选填] 备案号
  ICPNumber: '',
  
  // [选填] 公网安备号
  gongWangAnBei: '',

  // [必填] 身份验证的cookie名称（储存Token）
  authCookieName: 'token',

  // [必填] https://github.com/css-modules/css-modules
  classScopedName: '[hash:base64:5]',
  
  // [选填] 客户端打包后(dist/client/*)静态资源的前缀域名
  publicPath: '',

  api: {
    // [必填] websocket 地址
    socket: 'https://www.womenideas.cn/ws',
    // socket: 'http://localhost:9001/ws',
    // socket: 'http://192.168.199.167:9001/ws',
    server: 'https://www.womenideas.cn/',
    //  server: 'http://localhost:9001/'
    // server: 'http://192.168.199.167:9001',
  },

  aws: '2OexcA9S3wJfIMShOYcG5t7z4i1O58h8enPwqihL50qWRcAjfxTdn/Qwctt6QLEu6qyM/CprtWcDDxif4T9/HBpCzMfoyWXHavoKTq6fJVfzSoPvHNhlBOxtjKpSwLMC57wLD05Yj25kCX53WHrayJTeFUZwyOsSU8LRn2vgijv5v6tmcSt4tXDAl3IkW51Q3C+oANlAUkc83zkjW+ieeWLTDVyd3oUS7ZqtaTvFNJS5QjnsnooPKZQmLFbbkZEnYT10948wVLD9X2cGGAcyCJvlL8Yz7I3n6Qya4s6STde5YdiTdyCwoQH8Mz8OeKzZ92HBx2oyXzghA1T0uHU7Yq5sazm3VoE2B3DUfcvsrWnmLpsY7r9B7aypPMXHtdR8Cv7NGW2T6vFhfyluFx0zbtUv7Blal2Wg70HCmifWTuRP/ZgAEsUT8XrUPTpzepYUsq7csIVMdTouUY4i9BkN+sy/IWzMZXtcaGl3SjjqVDFnnAytxNq0Hotqq+L0uHXXBno/Jhdwj4K6YFOgMXsUO1jnk9Nt8zx9NQ5YoJzeUsQGbb5tLqLt3mY5OcBwrsPebgIrb0AAyG+VareyhzKegfuL8Xu9iT2RlszYrSt+4Xg=',
  
  // 社交账号登陆，需api支持
  third_party: {
    github: "https://github.com/login/oauth/authorize?client_id=104362fc274c82aedf92"
    //github: "https://github.com/login/oauth/authorize?client_id=c7a0d8b192cdf028576b"
  },
  // [选填] 添加分析统计脚本
  //analysisScript: ``,

  AES_KEY: 'MTAxZWNhbmFjMDFrbmFyZg=='
}

export default appConfig;
