import '../common.css';

import React, { useMemo } from 'react';
import { useState, createRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';

import { Icon } from '../components/basic'

import http from '../common/http';
import cookieUtil from '../common/cookieUtil';
import { base64Password } from '../common/crypto';
import { convertLinkType, isEmpty} from '../common/utils';

import appConfig from '../config/appConfig'

import NiceModal from '@ebay/nice-modal-react';
import EmailModal from './EmailModal';
import MyModal from './my-modal';
import SockJsClient from 'react-stomp';

const SignIn = ({ isLogin, setLogin }) => {

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [account_value, setAccountValue] = useState(cookieUtil.getAccountName());
  const [account_id, setAccountId] = useState(cookieUtil.getAccountId());

  const [validated1, setValidated1] = useState(false);
  const [validated2, setValidated2] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [emailRandom, setEmailRandom] = useState();
  const [noticeCount, setNoticeCount] = useState([]);

  const password = createRef();
  const account = createRef();
  const email = createRef();
  const randomValue = createRef();

  const num1 = Math.floor(Math.random() * 10 + 1);
  const num2 = Math.floor(Math.random() * 10 + 1);
  const sum = num1 + num2;
  const headers = useMemo(() => http.buildHeader(account_id, 'token'), [account_id]);

  useEffect(() => {
    // if (isLogin && !isEmpty(account_id)) {
    //   console.log('/api/notice/all');
    //   http.post('/api/notice/all', { 'accountId': account_id })
    //     .then(v => {
    //       setNoticeCount(v.data.totalCount);
    //     }).catch(function () {
    //       NiceModal.show(MyModal, { type: 'netError' });
    //       return;
    //     });
    // }
  }, []);


  function fetchAllNotice() {
    if (isLogin && !isEmpty(account_id)) {
      http.post('/api/notice/all', { 'accountId': account_id })
        .then(v => {
          setNoticeCount(v.data.desc.split(','));
        }).catch(function () {
          NiceModal.show(MyModal, { type: 'netError' });
          return;
        });
    }
  }

  function login(event) {
    let form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === false) {
      return;

    } else {
      setValidated1(true);
      http.post('/api/sign/signIn', {email: email.current.value, password: base64Password(password.current.value)})
      .then(v => {
        if(v.data.verify === true) {
          cookieUtil.saveCookie(v.data.name, v.data.accountId, v.data.photoUrl);
          setShow1(false);
          setLogin(true);
          setAccountValue(v.data.name);
          setAccountId(v.data.accountId);
          NiceModal.show(MyModal, { type : 'signSuccess' });

        } else {
          setValidated1(false);
          NiceModal.show(MyModal, { type : 'loginInError' });
        }
      }).catch(
        e => NiceModal.show(MyModal, { type : 'netError' })
      );
    }      
   }

  function signUp(event) {
    let form = event.currentTarget;
      // Event: Cancels Event (Stops HTML Default Form Submit)
      event.preventDefault();
      // Event: Prevents Event Bubbling To Parent Elements
      event.stopPropagation();

    if(randomValue.current.value != sum) {
      NiceModal.show(MyModal, { type : 'signUpCheckError' })
      return;
    }

    if (form.checkValidity() === false) {
      return;

    } else {
      setValidated2(true);
      http.post('/api/sign/email', { email: email.current.value })
        .then(v => {
          if (v.status === 200 && v.data.verify === true) {
            setEmailRandom(v.data.random);
            setIsVerify(true);
          } else {
            NiceModal.show(MyModal, { type: 'emailSendFailed' });
            setValidated2(false);
          }
        }).catch(function () {
          NiceModal.show(MyModal, { type: 'emailSendFailed' });
          setValidated2(false);
          return;
        })
    }
  }

  function submit() {
    http.post('/api/sign/signUp', {name: account.current.value, email: email.current.value, password: base64Password(password.current.value)} )
    .then(v => {
        if(v.data.verify === true) {
          setShow2(false); 
          setIsVerify(false);
          cookieUtil.saveCookie(v.data.name, v.data.accountId, v.data.photoUrl);
          setLogin(true);
          setAccountValue(v.data.name);
          NiceModal.show(MyModal, { type : 'signSuccess' });
        } else {
          NiceModal.show(MyModal, { type : 'signFailed' });
        }
      }).catch(function() { 
        NiceModal.show(MyModal, { type : 'netError' });
        return;
      })
  }

  function loginOut() {
    cookieUtil.deleteCookie();
    setLogin(false);
    setValidated1(false);
    NiceModal.show(MyModal, { type : 'loginOutSuccess' });
  }

  const noticeSpan = () => {
    const elements = [
      { key: 'comment', value: '评论' },
      { key: 'like', value: '点赞' },
      { key: 'chat', value: '私信' }
    ];
    let total = noticeCount.length > 0 ? Number(noticeCount[0]) + Number(noticeCount[1]) + Number(noticeCount[2]) : 0;
    return (
      <NavDropdown id="nav-notice-1" drop='end' className="nav-notice" title={total > 0 ?
        <span className="text-danger"> <Icon>notifications</Icon> <small>{total}</small></span>
        : <Icon>notifications</Icon>}>
        { elements.map((e, idx) => (
          <NavDropdown.Item key={idx} href={'/notices/' + e.key} >
            {e.value}<small className='text-danger mx-2'><strong>{noticeCount[idx] > 0 && noticeCount[idx]}</strong></small>
          </NavDropdown.Item>
        ))
        }
      </NavDropdown>
    );
  }

  return (
    <>
      <div className="sign">
        { (isLogin) ? 
          <>
            <Navbar collapseOnSelect expand="sm" className="bg-body-tertiary">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-space-around">
                <Nav className="nav_dropdown_bg">
                  {noticeSpan()}

                  <NavDropdown id="nav-site" title="发布">
                    <NavDropdown.Item href='/siteNew/type1'>图文</NavDropdown.Item>
                    <NavDropdown.Item href='/siteNew/type2'>资源链接</NavDropdown.Item>
                    <NavDropdown.Item href='/siteNew/type3'>长文章</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown id="nav-personal" title={account_value} menuVariant="dark">
                    <NavDropdown.Item href='/accountDetail/home'>修改个人信息</NavDropdown.Item>
                    <NavDropdown.Item href='/accountDetail/sites'>我的发布</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <Button variant="outline-secondary" className="dropdown-item" onClick={loginOut}>
                      退出登录 <Icon>exit_to_app</Icon>
                    </Button>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <SockJsClient
              url={appConfig.api.socket}
              headers={headers}
              topics={['/queue/' + account_id + '/notice']}
              onConnect={() => fetchAllNotice(account_id)}
              onConnectFailure={() => console.debug('connect failed')}
              onDisconnect={() => console.debug('disconnected')}
              onMessage={(msg) => { console.debug(msg); setNoticeCount(msg.message.split(',')) }}
            // debug={true}
            />       
          </>
          :
          <>
            <Button variant="light" size="md" onClick={() => { setShow1(true); setShow2(false); }}> Log in </Button>{' '}
            <Button variant="outline-secondary" size="md" onClick={() => { setShow2(true); setShow1(false); }}> Sign up </Button>

        {show1 &&
          <Modal className="sign_modal" show={setShow1} onHide={() => setShow1(false)}>
            <Modal.Header closeButton>
              <Modal.Title>登录</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form validated={validated1} onSubmit={login}>
                <Form.Group className="mb-3" controlId="controlInput1">
                  <Form.Label>邮箱</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    ref={email}
                    autoFocus
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                  </Form.Control.Feedback> 
                </Form.Group>

                <Form.Group className="mb-3" controlId="controlInput2">
                <Form.Label>密码</Form.Label>
                  <Form.Control
                    type="password"
                    ref={password}
                    required
                  />
                 <Form.Control.Feedback type="invalid">
                    Please provide a correct password.
                  </Form.Control.Feedback> 
                </Form.Group>
            
                <div className="d-grid gap-2">
                <Button variant="outline-secondary" onClick={() => setShow1(false)}>
                Close
                 </Button>
                 <Button variant="success" type="submit" disabled={validated1}>
                  {validated1 ? '正在登录...' : 'Login'}
                 </Button>
                 </div>
              </Form>
              <div className="third-login pt-4">
                <small><span className="text-muted">其他方式登录{' ->'}</span></small>
                <a href={appConfig.third_party.github}>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="btn-tooltip">github</Tooltip>}>
                    <Image src={convertLinkType('github')} roundedCircle />
                  </OverlayTrigger>
                </a>
                {/* <Image src={convertLinkType('zhihu')} roundedCircle /> */}
              </div>
            </Modal.Body>
              <Modal.Footer>      
              </Modal.Footer>
            <div className="forget-link">
                <a href="/forget"> -- 忘记密码 -- </a>
            </div>
          </Modal>
        }
        {show2 &&
          <Modal className="sign_modal" show={setShow2} onHide={() => setShow2(false)}>
          <Modal.Header closeButton>
            <Modal.Title>- 快速注册 -</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form validated={validated2} onSubmit={signUp}>
                <Form.Group className="mb-3" controlId="fromControlInput1">
                  <Form.Label>邮箱</Form.Label>
                  <InputGroup hasValidation>
                   <Form.Control  
                    type="email"           
                    size="sm"
                    placeholder="name@example.com"
                    ref={email}
                    maxLength={50}
                    autoFocus
                    required                   
                  /> 
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                  </Form.Control.Feedback> 
                  </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="fromControlInput2">
                  <Form.Label>密码</Form.Label>
                  <Form.Control
                    type="password"
                    size="sm"
                    ref={password}
                    placeholder="密码为6-10位的数字和字母组合"
                    required
                    pattern="^[a-zA-Z0-9]{6,10}$"
                  />
                  <Form.Control.Feedback type="invalid">
                    密码需要在6-10位的数字和字母组合，不能包含特殊字符
                  </Form.Control.Feedback> 
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="fromControlInput3">
                  <Form.Label>用户名</Form.Label>
                  <Form.Control
                    type="text"
                    size="sm"
                    ref={account}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid accountName.
                  </Form.Control.Feedback> 
                  </Form.Group>          
                  <Form.Group className="mb-3">
                    <Form.Check
                      required
                      label="同意网站协定"
                      feedback="You must agree before submitting."
                      feedbackType="invalid"
                      className="form_check"
                    />
                  </Form.Group> 
                  <div className="d-grid gap-2">
                    <Button variant="outline-secondary" onClick={() => setShow2(false)}> Close </Button>
                    <Button type="submit" variant="success" disabled={validated2 && (!isVerify)} > {validated2 && (!isVerify) ? '注册中...' : 'SignUp'} </Button>
                  </div>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="random_txt"> <em> {num1} + {num2} = </em></InputGroup.Text>
                  <Form.Control ref={randomValue} type="text" placeholder="?" />
                  <InputGroup.Text className="text-muted"> <small><em> 请输入正确的计算结果  </em></small></InputGroup.Text>
                </InputGroup>
              </Modal.Footer>
              <div className="forget-link">
                <a href="/about"> -- 关于本站 -- </a>
              </div>
            </Modal>
        }
        { 
          isVerify && <EmailModal random={emailRandom} submit= {submit} isVerify={isVerify}/>
        }
        </>
        }
      </div>
    </>
  );
}
export default SignIn;



