import cookie from 'react-cookies'
import appConfig from '../config/appConfig'
import {getPhoto} from './utils'

let cookieUtil = {
  checkCookie:'',
  saveCookie:'',
  deleteCookie: '',
  getAccountId: '',
  getAccountName: '',
  getPhotoUrl: ''
};


cookieUtil.checkCookie = function() {
  if(cookie.load('account') && cookie.load('accountId')) {
    return true;
  }else {
    return false;
  }
}

cookieUtil.saveCookie = function(account, accountId, photoUrl) {
  let cookieTime = new Date(new Date().getTime() + 7 * 24 * 3600 * 1000); 
  cookie.save('account', account, {path: "/", expires: cookieTime});
  cookie.save('accountId', accountId, {path: "/", expires: cookieTime});
  cookie.save('photoUrl', photoUrl, {path: "/", expires: cookieTime});
}

cookieUtil.getAccountId = function() {
  if(cookie.load('account') && cookie.load('accountId')) {
    return cookie.load('accountId');
  }else {
    return null;
  }
}

cookieUtil.getAccountName = function() {
  if(cookie.load('account') && cookie.load('accountId')) {
    return cookie.load('account');
  }else {
    return null;
  }
}

cookieUtil.getPhotoUrl = function() {
  if(cookie.load('account') && cookie.load('accountId')) {
    return cookie.load('photoUrl');
  } else {
    return getPhoto(cookie.load('account'));
  }
}

cookieUtil.deleteCookie = function() {
  let expiresTime = new Date(new Date().getTime() - 24 * 3600 * 1000); 
  cookie.save('account', 0, {path: "/", expires: expiresTime});
  cookie.save('accountId', 0, {path: "/", expires: expiresTime});
  cookie.save('photoUrl', 0, {path: "/", expires: expiresTime});
  cookie.remove('account', {path: "/", domain: appConfig.domainName});
  cookie.remove('accountId', {path: "/", domain: appConfig.domainName});
  cookie.remove('photoUrl', {path: "/", domain: appConfig.domainName});
}

export default cookieUtil;


