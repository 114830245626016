import '../App.css';
import { Icon } from './basic'

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';

import { useNavigate } from 'react-router-dom';

import { timeFormat, convertChildTabToMap, shortTextbody } from '../common/utils';


export default function MainList({ arrays, type = 1 }) {
  const navigate = useNavigate();
  const tabMap = convertChildTabToMap();

  return (
    <Row xs={2} lg={5} className="g-5">
      {arrays && arrays.length > 0 && arrays.map((item, idx) => (
        <Col key={idx}>
          <Card border="light" key={idx} className="card_list" text="dark" onClick={() => { navigate(`/siteDetail/${item.siteId}`) }} >
            <Card.Img variant="top" className="card_list_img"
              src={item.homeImageUrl || "https://wnisbucket.s3.ap-southeast-1.amazonaws.com/default/default.jpeg"} alt="homeImageUrl" />
            <Card.Body>
              <Card.Title as="h6">{item.title && item.title.substring(0, 20)}
                {tabMap && item.tabId > 0 &&
                  <Badge pill bg="light" text="secondary"> {tabMap.get(item.tabId)}</Badge>
                }
              </Card.Title>
              <span className="text-muted card_body_span">
                {item && (item.type === 2) ? shortTextbody(item.textBody) + '...' : item.textBody.substring(0, 50) + '...'}
              </span>
            </Card.Body>
            <Card.Footer>
              {type == 1 ?
                <> <Icon>favorite_border</Icon> {item.likeCount} </>
                :
                <><small className="text-muted"> {timeFormat(item.createTime)}</small> <Icon>trending_up</Icon> </>
              }
            </Card.Footer>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
