import '../App.css'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import appConfig from '../config/appConfig'

function Footer() {

  return (
    <Container fluid className="footer">
      <Row className="footer_row_first">
        <Col>Ideas</Col>
        <Col>Design</Col>
        <Col>Links</Col>
      </Row>
      <Row>
       <Col> imagination </Col>
       <Col> UI </Col>
       <Col><a rel="noreferrer" href="https://spring.io/projects/spring-cloud/" target="_blank"> Spring Cloud </a></Col>
      </Row>
      <Row>
        <Col> innovation </Col>
         <Col> <a rel="noreferrer" href="https://react-bootstrap.github.io" target="_blank"> Bootstrap </a> </Col>
         <Col> <a rel="noreferrer" href="https://legacy.reactjs.org" target="_blank"> React </a> </Col>
      </Row>
      <Row>
        <Col> creation </Col>    
       <Col>  CSS </Col>
       <Col> <a rel="noreferrer" href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/restoring-objects.html" target="_blank"> AWS S3 </a></Col>
      </Row>
      <Row className="footer_row_end">
      <Col sm={4}> <small><em>{appConfig.description}</em></small> </Col>
      <Col sm={8}> </Col>
      </Row>
      <hr/>
      <p>Copyright © 2023-2024 Fantastic Cube</p>
      <a rel="noreferrer" href="https://beian.miit.gov.cn/" target="_blank"><small>皖ICP备2023008071号-1</small></a>
   </Container>
  );

}
export default Footer;
