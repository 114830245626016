import logo from './logo.png';
import './App.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Routes, Route } from "react-router-dom";  //v6+

import SignIn from './components/Sign';
import OffcanvasExpand from './components/OffcanvasExpand';

import Footer from './pages/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Forget from './pages/Forget';
import AlertMessage from './pages/AlertMessage';

import cookieUtil from './common/cookieUtil';
import {isEmpty} from './common/utils';

function App() {

  const [isLogin, setLogin] = useState(cookieUtil.checkCookie());
  const [accountId, setAccountId] = useState(cookieUtil.getAccountId());

  //lazy loading enhancement
  const SiteNew = lazy(() => import('./pages/SiteNew'));
  const SearchList = lazy(() => import('./pages/Search'));
  const SiteNewCustom = lazy(() => import('./pages/SiteNewCustom'));
  const SiteResource = lazy(() => import('./pages/SiteResource'));
  const SiteDetail = lazy(() => import('./pages/SiteDetail'));
  const AccountDetail = lazy(() => import('./pages/AccountDetail'));
  const AccountView = lazy(() => import('./pages/AccountView'));
  const TabDetail = lazy(() => import('./pages/TabDetail'));
  const Ticket = lazy(() => import('./pages/Ticket'));
  const LoginCallback = lazy(() => import('./pages/LoginCallback'));
  const Notices = lazy(() => import('./pages/Notices'));

  useEffect(() => {
    if (isLogin && !isEmpty(cookieUtil.getAccountId())) {
      setAccountId(cookieUtil.getAccountId());
    }
  }, [isLogin]);

  // console.log(process.env);

  return (
    <div className="App">
      <div id="app-header-fixed" className="app-header-fixed">
        <header>
          <Container className="header_top">
            <Row>
              <Col><OffcanvasExpand /></Col>
              <Col lg={6} sm={4} > <a href="/"> <img src={logo} className="mt-2 App-logo" alt="logo" /> </a></Col>
              <Col>
                <SignIn isLogin={isLogin} setLogin={setLogin} />
              </Col>
            </Row>
          </Container>
        </header>
      </div>

      <div className="app_background">
        <Suspense fallback={<Spinner animation="border" variant="secondary" />}>
          <Routes fallbackElement={<Spinner animation="border" variant="secondary" />} >   // 统一处理请求loading状态
            <Route path="/" element={<Home />} />
            <Route path="*" element={<AlertMessage type='notFound' isLogin={isLogin} />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/404" element={<AlertMessage type='notFound' isLogin={isLogin} />} />
            <Route path="/success" element={<AlertMessage type='success' />} />
            <Route path="/forget" element={<Forget />} />
            <Route path="/tabDetail/:tab" element={<TabDetail accountId={accountId} />} />
            <Route path="/feedback" element={<AlertMessage type='feedback' />} />
            <Route path="/login/callback/" element={<LoginCallback setLogin={setLogin}/>} />
            
            {isLogin &&
              <>
                <Route path="/siteNew/type1" element={<SiteNew accountId={accountId} />} />   //组件传参
                <Route path="/siteNew/type2" element={<SiteResource accountId={accountId} />} />
                <Route path="/siteNew/type3" element={<SiteNewCustom accountId={accountId} />} />
                <Route path="/search/" element={<SearchList />} />
                <Route path="/search/:keyword" element={<SearchList />} />
                <Route path="/siteDetail/:siteId" element={<SiteDetail accountId={accountId} />} />
                <Route path="/accountView/:id" element={<AccountView accountId={accountId} />} />
                <Route path="/notices/comment" element={<Notices accountId={accountId} targetTab='comment' />} /> 
                <Route path="/notices/like" element={<Notices accountId={accountId} targetTab='like'/>} /> 
                <Route path="/notices/chat" element={<Notices accountId={accountId} targetTab='chat'/>} /> 

                <Route path="/accountDetail/home" element={<AccountDetail accountId={accountId} targetTab={'home'} />} />
                <Route path="/accountDetail/sites" element={<AccountDetail accountId={accountId} targetTab={'sites'} />} />
                <Route path="/ticket/apply" element={<Ticket accountId={accountId} typeName='申请' type={1} />} />
                <Route path="/ticket/suggestion" element={<Ticket accountId={accountId} typeName='建议' type={2} />} />
                <Route path="/ticket/report" element={<Ticket accountId={accountId} typeName='举报' type={3} />} />
                <Route path="/ticket/bug" element={<Ticket accountId={accountId} typeName='我发现了bug' type={4} />} />
                <Route path="/ticket/addTab" element={<Ticket accountId={accountId} typeName='申请添加标签' type={5} />} />
                <Route path="/reset" element={<Forget title='修改密码'/>} />
              </>}
            {/* <CacheRoute exact path="/tabDetail/:tab" element={<TabDetail />} when="always" />  */}

          </Routes>
        </Suspense>
      </div>

      <Footer />
    </div>
  );

}

export default App;
