import dataJson from '../config/tabs.json';
import link1 from '../images/link1.png';
import link2 from '../images/link2.png';
import link3 from '../images/link3.png';
import link4 from '../images/link4.png';

export function getTabName(tabId) {
  let tab = dataJson.tabs.find((item) => item.tab_Id == Math.floor(tabId/10));
  if(tab) {
    let subTab = tab.child_tabs.find((item) => item.t_id === tabId);
    return {'tab_tame': tab.tab_name, 'sub_id': subTab.t_id, 'sub_name':subTab.t_name};
  }
  return null;
}

export function convertTabToMap() {
  let tab_map = new Map();
  dataJson.tabs.map((dj) => {
    tab_map.set(dj.tab_Id, dj.child_tabs);
  })
  return tab_map;
}

export function convertToMap() {
  let tab_map = new Map();
  dataJson.tabs.map((dj) => {
    tab_map.push(dj.tab_Id, dj.tab_name);
  })
  return tab_map;
}

export function convertChildTabToMap() {
  let tab_map = new Map();
  dataJson.tabs.map((dj) => {
    dj.child_tabs.map((item) => {
      tab_map.set(item.t_id, item.t_name);
    })
  })
  return tab_map;
}

export function solveLinks(links) {
  let arrLinks = [];
  if(links !== null) {
    arrLinks = JSON.parse(links);
  }
  return arrLinks;
}

export function convertLinkType(link) {
  if(link.includes("douban")) {
    return link1;
  } else if(link.includes("zhihu")) {
    return link2;
  } else if(link.includes("github")) {
    return link3;
  } else if(link.includes("juejin")) {
  return link4;
  }  
  return null;
}

export function convertLinkMap() {
  let map = new Map();
  map.set('douban', link1);
  map.set('zhihu', link2);
  map.set('github', link3);
  map.set('juejin', link4);
  return map;
}

export function timeFormatString(time) {
  // 创建一个新的Date对象，使用时间戳作为参数
  if(!isNaN(Number(time))) time = Number(time);
  var date = new Date(time);
  const today = new Date();

  // 获取年、月、日、时、分、秒
  var year = date.getFullYear();
  var month = date.getMonth() + 1; // 月份从0开始，所以需要加1
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();

  // 补零操作，确保每个部分都是两位数
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  hour = hour < 10 ? '0' + hour : hour;
  minute = minute < 10 ? '0' + minute : minute;
  second = second < 10 ? '0' + second : second;

  // 拼接成最终的格式化字符串
  if (today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()) {
    return '今天 ' + hour + ':' + minute + ':' + second;
  }
  return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
}

export function timeFormat(time) {
  if(!time) return;
  let currentTime = Date.now();
  let dateBegin = new Date(time).getTime();;
  // console.log(currentTime);
  // console.log(new Date().getTime()); 
  // console.log(dateBegin);
  // console.log(new Date(time.substring(0, 19).replace('T', ' ')).getTime());

  // let currentTime = new Date().getTime();
  // let dateBegin = new Date(time.substring(0, 19).replace('T', ' ')).getTime();

  let dateDiff = currentTime - dateBegin;//时间差的毫秒数
  if(dateDiff < 60000) return '刚刚';
  let dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数
  if (dayDiff > 60) return new Date(time).toLocaleString('zh-CN');
  if (dayDiff > 10) return dayDiff + "天前";

  let leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000))//计算出小时数
  let leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000))//计算相差分钟数
  return (dayDiff ? dayDiff + "天" : '') 
         + (hours ? hours + "小时" : '') 
         + (minutes ? minutes + "分钟" : '')
         + '前';
}

export function getLocalTime(utcTime) {
  // console.log(new Date(utcTime));
  // console.log(new Date(utcTime).getTimezoneOffset());
  // console.log(new Date(utcTime).toLocaleString());
  return new Date(utcTime).toLocaleString();
}

export function shortTextbody(value) {
  let str = '';
  value && JSON.parse(value).map((item, idx) => {
    if (item.type === 'heading-one' || item.type === 'heading-two' )
      str += item.children[0].text;
  })
  return str+'...';
}


export function countWords(text) {
  // 将文本内容分割成单词数组
  var words = text.trim().split(/\s+/);
  // 初始化字数为0
  var wordCount = 0;
  // 遍历数组并计算每个单词的长度
  for (var i = 0; i < words.length; i++) {
    wordCount += words[i].length;
  }
  // 返回总字数
  // 阅读时长 1000字/5min
  var mins = Math.round(5*wordCount/1000);
  if(mins === 0) return '总字数约: '+wordCount +'｜阅读时长< 1mins';
  return '总字数约: '+wordCount +'｜阅读时长: ' +mins +'mins';
}


export function getPhoto(name) {
  if(isEmpty(name)) return null;
  const context = require.context("../images/photos/", true, /\.(png|jpg)$/);
  let len = (name.length+1) % 10;
  let imgs = context("./" + len + ".png");
  return imgs;
}

export function getPhotoUrl(name, url) {
  if(isEmpty(url)) {
    return getPhoto(name);
  }else {
    return url;
  }
}

export function isEmpty(str) {
  return (!str || str.trim() === "" || str.length === 0);
}