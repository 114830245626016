import '../common.css'
import appConfig from '../config/appConfig'

function About() {

  return (
    <div>
      <br/>
      <h2> 关于本站 </h2> 
      <br/>      
      <p> 这是一个分享讨论金融、技术相关知识的网站。</p>   
      <p> 抵制口水贴、反串和不良情感传播，一旦发现立即封帖、拉黑名单。</p>   
      <p> 希望各位在繁忙、迷茫时候能够在本站找到自己感兴趣的话题或者知识，大方热情勇敢地留下你的足迹吧！</p>   
      <p> 期待你我携手助力广大女性在科技行业成长，也希望各位女士能够踊跃为本站发展建言献策！</p>
      <br/>  
      <br/>
      <br/>
      <p> —— {appConfig.releaseDate}</p>
      <p> {appConfig.name} </p>  
      <br/>  
      <br/>
      <br/>  
      <br/>
      <p><strong>「 温馨提示：请勿随意发布个人隐私，以免信息泄露 」</strong></p>
      <br/>  
      <br/>
    </div>
  );
}

export default About;
