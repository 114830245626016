import '../common.css'
import Alert from 'react-bootstrap/Alert';


export default function AlertMessage({ type, isLogin=true }) {
  const messageMap = new Map([
    ["success",  {variant : 'success', head : '恭喜你', body: '发布成功，正在审核中...'}],
    ["notFound", {variant : 'danger', head : 'Ops! NOT FOUND', body: '这个页面似乎逃跑了', body_login: '请登录后再自由活动'} ],
    ["feedback", {variant : 'success', head : '感谢您的反馈', body: '您的反馈已经提交至Women ideas审核组'}],
    ["addTab", {variant : 'success', head : '感谢您的申请', body: '您的申请已经提交至Women ideas审核组'}],
  ]);
  let message = messageMap.get(type);

  return (
    <Alert variant={message.variant} className="alert_body">
      <Alert.Heading> {message.head} </Alert.Heading>
      <p>
        {isLogin ? message.body : message.body_login}
      </p>
    </Alert>
  );
};