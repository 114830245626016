import '../App.css'
import { Icon } from './basic'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Offcanvas from 'react-bootstrap/Offcanvas'

import appConfig from '../config/appConfig'


export default function OffcanvasExpand() {
  let expand = false;
  return (
    <>
      <Navbar key={expand} expand={expand} className="logo_nav">
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="start"
          className="opacity-75 nav_offcanvas"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="mt-3" id={`offcanvasNavbarLabel-expand-${expand}`}>
              <Icon> cloud_queue </Icon> <strong>关于本站</strong>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 mt-3">
              <Nav.Link href="/about"> <Icon>description</Icon> 本站介绍</Nav.Link>
              <NavDropdown
                title="联系站长"
                id="navDropdown1">
                <NavDropdown.Item>
                  <Icon> mail_outline </Icon> {appConfig.contactEmail}
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="其他"
                id={`offcanvasNavbarDropdown-expand-${expand}`}>
                <NavDropdown.Item href="/ticket/apply"> <Icon> accessibility </Icon> 申请加入审核组</NavDropdown.Item>
                <NavDropdown.Item href="/ticket/suggestion"> <Icon> pan_tool </Icon> 我有建议 </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/ticket/report">
                  举报
                    </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <Navbar.Brand className="logo_style"> Women ideas </Navbar.Brand>
      </Navbar>
    </>
  );
}