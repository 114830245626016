import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';

import { useState, createRef} from 'react';
import { useNavigate } from "react-router-dom";
import NiceModal from '@ebay/nice-modal-react';
import MyModal from '../components/my-modal';

import cookieUtil from '../common/cookieUtil';
import http from '../common/http';
import {base64Password} from '../common/crypto';

export default function Forget({title='找回密码'}) {
  const [isLoading, setLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [emailRandom, setEmailRandom] = useState('');

  const passwordNew = createRef();
  const passwordNewAgain = createRef();
  const email = createRef();
  const random = createRef();
  const navigate = useNavigate();

  function sendEmail(event) {
    let form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    //console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      return;
    }
    //send email;
    setLoading(true);
    http.post('/api/sign/email', {email: email.current.value} )
    .then(v => {
        if(v.data.verify === true) {
          //console.log(v.data.random);
          setEmailRandom(v.data.random);
          setIsVerify(true);
        }else {
          NiceModal.show(MyModal, { type : 'emailSendFailed' });
        }
      }).catch(function() { 
        NiceModal.show(MyModal, { type : 'netError' });
        return;
      })
  };

  function confirmUpdate() {
    const regex = new RegExp('^[a-zA-Z0-9]{6,10}$');
    if(passwordNew.current.value !== passwordNewAgain.current.value) {
      NiceModal.show(MyModal, { type : 'resetPasswordFailed' });
      return;
    }
    if(!regex.test(passwordNew.current.value) || !regex.test(passwordNewAgain.current.value)) {
      NiceModal.show(MyModal, { type : 'resetPasswordCheck' });
      return;
    }

    if(random.current.value == emailRandom) {
      http.post('/api/sign/NewPassword', {email: email.current.value, newPassword: base64Password(passwordNew.current.value)} )
      .then(v => {
          if(v.data.verify === true) {
            NiceModal.show(MyModal, { type : 'resetPasswordSuccess' });
            cookieUtil.deleteCookie();
            navigate('/');
          }else {
            NiceModal.show(MyModal, { type : 'updateFailed' });
          }
        }).catch(function() { 
          console.log("rejected the promise, something wrong happened");
          return;
        })
    } else {
      NiceModal.show(MyModal, { type : 'verifyCodeCheck' });
    } 
  }

  const btn_value = () => {
    if(isLoading && isVerify) return '邮箱验证码已发送';
    else if(isLoading) return '邮箱验证码发送中';
    else return '邮箱接收验证号';
  }

  return (
    <Container fluid="sm" className="container-padding form_simple">
      <span className="span_simple"> - {title} - </span>
      <Form onSubmit={sendEmail}>
        <Form.Group className="mb-3" controlId="fromControlInput1">
          <InputGroup className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-default">
              请输入邮箱
           </InputGroup.Text>
            <Form.Control
              size="sm"
              type="email"
              ref={email}
              placeholder="name@example.com"
              maxLength={50}
              autoFocus
              required
            />
            <Form.Control.Feedback type="invalid">
              hi, Please provide a valid email.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="verify code"
            aria-label="verify code"
            ref={random}
            aria-describedby="basic-addon2"
          />
          <Button variant="outline-secondary" type="submit" disabled={isLoading}>
            {btn_value()}
          </Button>
        </InputGroup>
        {isVerify && 
        <>
                  <Form.Group className="mb-3" controlId="fromControlInput2">
                  <Form.Label className="text-primary" >新密码（密码需要在6-10位的数字和字母组合，不能包含特殊字符）</Form.Label>
                  <Form.Control
                    type="password"
                    ref={passwordNew}
                    required
                    pattern="^[a-zA-Z0-9]{6,10}$"
                  />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="fromControlInput3">
                  <Form.Label className="text-primary" >再输入一遍新密码</Form.Label>
                  <Form.Control
                    type="password"
                    ref={passwordNewAgain}
                    required
                    pattern="^[a-zA-Z0-9]{6,10}$"
                  />
                  </Form.Group>
                  <div className="d-grid gap-2">
                  <Button variant="outline-secondary" href="/"> Back </Button>
                  <Button variant="primary" onClick={confirmUpdate}> 确认 </Button>
        </div>
        </>
        }
      </Form>

    </Container>
  )
}