import axios from 'axios';
import appConfig from '../config/appConfig'
import { deBase64, aesEncrypt } from './crypto'

axios.defaults.timeout = 10000;  //设置前台访问后台的超时时间为10s
axios.defaults.withCredentials = true;  //允许跨域
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
//baseURL 
axios.defaults.baseURL = appConfig.api.server;


let http = {
  post: '',
  get: '',
  sleep: '',
  fetch: '',
  buildHeader: '',
};

http.post = async function (api, data) {
  //console.log(data);
  let timestamp = new Date().getTime();
  let appTime = appConfig.APP_ID + timestamp;
  let sign = aesEncrypt(appTime, deBase64(appConfig.AES_KEY).split("").reverse().join(""));
  let body = aesEncrypt(data, deBase64(appConfig.AES_KEY).split("").reverse().join(""));
  try {
    return new Promise((resolve, reject) => {
      axios.post(api, { appId: appConfig.APP_ID, timestamp: timestamp, sign: sign, body: body }).then(res => {
        resolve(res);
      }).catch((error) => {
        //console.log(error);
        //resolve({'status': error.response.status, 'error': error.message});
        reject({
          'status': (error.code === 'ERR_NETWORK') ? error.code : error.response.status,
          'error': error.message
        });
      });
    })
  } catch (error) {
    console.log(error);
  }
};

http.get = function (api, data) {
  //console.log(data);
  try {
    return new Promise((resolve, reject) => {
      axios.get(api, {
        params: data
      }).then(res => {
        resolve(res);
      }).catch((error) => {
        //throw new Error(error);
        //resolve({'status': error.response.status, 'error': error.message});
        reject({
          'status': (error.code === 'ERR_NETWORK') ? error.code : error.response.status,
          'error': error.message
        });
      });
    })
  } catch (error) {
    console.log(error);
  }
};

http.sleep = function (interval) {
  //after interval, call back resolve
  return new Promise(resolve => {
    console.log("sleep start, interval= " + interval);
    setTimeout(resolve, interval);
    console.log("sleep end, interval= " + interval);
  })
}

http.buildHeader = function(userId, token) {
  let timestamp = new Date().getTime();
  let param = appConfig.APP_ID + timestamp + userId;
  let sign = aesEncrypt(param, deBase64(appConfig.AES_KEY).split("").reverse().join(""));
  return { 'userId': userId, 'date': timestamp, 'authorization': sign, 'token': token };
}

// async函数返回的是 Promise 对象， 可以使用then方法添加回调函数。
//当函数执行的时候，一旦遇到await就会先返回，等到异步操作完成，再接着执行函数体内后面的语句。

async function sleepTest() {
  //sleep先后执行
  const res1 = await http.sleep(9000);
  const res2 = await http.sleep(4000);
  return res2;
};

export default http;