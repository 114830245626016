import '../common.css';
import { useState, createRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

export default function EmailModal({isVerify, random, submit}) {
  const [show, setShow] = useState(isVerify);
  const verifyCode = createRef(); //email

  const handleClose = () => {
    if(verifyCode.current.value == random) {
      submit();
      setShow(false);
    } else {    
      alert("请输入正确的验证码");
    }
  }

  return (
      <Modal className="modal-common" show={show} onHide={()=> setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>请查看邮箱验证码</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="input1">
              <Form.Control
                placeholder="verify code"
                ref={verifyCode}
                autoFocus
                required
              />
            </Form.Group>     
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            确认
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

// export default function AlertModal({ isAlertShow, title, message }) {
//   const [show, setShow] = useState(isAlertShow);

//   return (
//     <Modal
//       size="sm"
//       show={show}
//       onHide={() => setShow(false)}
//       aria-labelledby="modal-sizes-title-sm"
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="modal-sizes-title-sm">
//           {title}
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>{message}</Modal.Body>
//     </Modal>
//   )
// };


export const myModal = () => NiceModal.create(({ title, message }) => {
  // Use a hook to manage the modal state
  const modal = useModal();
  return (
    <Modal
      size="sm"
      show={modal.visible}
      onHide={() => modal.hide()}
      onExit={() => modal.remove()}
      aria-labelledby="modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-sizes-title-sm">
          {title}
        </Modal.Title>
      </Modal.Header>
      {/* <Modal.Body>{message}</Modal.Body> */}
    </Modal>
  );
});

