import CryptoJS from 'crypto-js'

/**
 *  随机生成 AESKEY
 */
export function createAesKey () {
  const expect = 16
  let str = Math.random().toString(36).substr(2)
  while (str.length < expect) {
    str += Math.random().toString(36).substr(2)
  }
  str = str.substr(0, 16)
  return str
}

/**
 * AES 加密
 * @param word 待加密字段
 * @param keyStr 加密 key
 * @returns {string} 返回加密字段
 */
export function aesEncrypt (word, keyStr) {
  const key = CryptoJS.enc.Utf8.parse(keyStr)
  let srcs = ''
  switch (typeof (word)) {
    case 'string':
      srcs = CryptoJS.enc.Utf8.parse(word)
      break
    case 'object':
      srcs = CryptoJS.enc.Utf8.parse(JSON.stringify(word))
      break
    default:
      srcs = CryptoJS.enc.Utf8.parse(word.toString())
  }
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {iv: key, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7})
  return encrypted.toString()
}

/**
 * AES 解密
 * @param word 待解密数据
 * @param keyStr 解密 key
 * @returns 
 */
export function aesDecrypt (word, keyStr) {
  const key = CryptoJS.enc.Utf8.parse(keyStr)
  const decrypt = CryptoJS.AES.decrypt(word, key, { iv: key, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  let srcs = ''
  switch (typeof (decrypt)) {
    case 'string':
      srcs = CryptoJS.enc.Utf8.stringify(decrypt).toString()
      break
    case 'object':
      srcs = JSON.parse(CryptoJS.enc.Utf8.stringify(decrypt))
      break
    default:
      srcs = CryptoJS.enc.Utf8.stringify(decrypt).toString()
  }
  return srcs;
}

export function base64Password (password) {
  let str = CryptoJS.enc.Utf8.parse(password);
  return CryptoJS.enc.Base64.stringify(str);
}

export function deBase64 (value) {
  var decodedStr = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(value));
  return decodedStr;
}

/**
 * 随机生成文件名
 * @param acccountId 
 * @returns {string} 文件名 .jpeg
 */
export function generateFname (acccountId) {
  var max = 999999;
  var min = 100000;
  var Range = max - min;
  var random = min + Math.round(Math.random() * Range);
  var now = new Date().getTime();
  //console.log(''+ random+'-'+ now);
  return acccountId + '-' + random + '-' + now + '.jpeg';
  
  //return crypto.randomUUID().substring(0, 16) + '.jpeg';;
}