import '../common.css';
import Modal from 'react-bootstrap/Modal';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Icon } from './basic'


const noticeMap = new Map([
  ['systemError', {title : '系统故障', message : '请稍后重试...', theme: 'text-danger'}],
  ['netError', {title : '温馨提示', message : '网络故障，请稍后重试...', theme: 'text-danger'}],

  ['emailSendFailed', {title : '提示', message : '邮件发送失败！请稍后重试', theme: 'text-danger'}],
  ['loginInError', {title : '提示', message : '用户名或者密码错误!', theme: 'text-danger'}],
  ['loginFailed', {title : '提示', message : '登录失败', theme: 'text-danger'}],
  ['loginOutSuccess', {title : '提示', message : '成功退出!', theme: 'text-success'}],
  ['loginInfo', {title : '提示', message : '请先登录', theme: 'text-danger'}],
  ['signSuccess', {title : '提示', message : 'Welcome to Women ideas', theme: 'text-success'}],
  ['signFailed', {title : '提示', message : '注册失败!!!', theme: 'text-danger'}],
  ['signUpCheckError', {title : '提示', message : '计算结果有错哦！', theme: 'text-danger'}],

  ['searchCheckError', {title : '提示', message : '搜索不能为空！', theme: 'text-danger'}],
  ['searchTooLong', {title : '提示', message : '搜索内容过长，请酌情缩短', theme: 'text-danger'}],

  ['siteNotExist', {title : '提示', message : '发布不存在！', theme: 'text-danger'}],
  ['siteDislike', {title : '提示', message : '你取消了对文章的喜欢', theme: 'text-primary'}],
  ['siteLike', {title : '提示', message : '你喜欢了文章', theme: 'text-info'}],
  ['cotentIsEmpty', {title : '提示', message : '内容为空，请输入内容', theme: 'text-danger'}],
  ['cotentIsLong', {title : '提示', message : '评论控制在300字内', theme: 'text-danger'}],
  ['commentSuccess', {title : '提示', message : '你已评论了文章', theme: 'text-success'}],
  ['replySuccess', {title : '提示', message : '感谢你的回复', theme: 'text-success'}],
  ['commentIsEmpty', {title : '提示', message : '暂无评论', theme: 'text-danger'}],
  ['commentNoMore', {title : '提示', message : '无更多评论', theme: 'text-danger'}],
  ['updateIsNull', {title : '提示', message : '你没有更新哦', theme: 'text-danger'}],
  ['linkTooLong', {title : '提示', message : '链接过长', theme: 'text-danger'}],
  ['linkIsNotValid', {title : '提示', message : '无效链接', theme: 'text-danger'}],
  ['linkScope', {title : '温馨提示', message : '链接仅限douban、github、zhihu、juejin', theme: 'text-danger'}],
  ['updatePhotoSuccess', {title : '提示', message : '新头像get!', theme: 'text-success'}],

  ['updateSuccess', {title : '提示', message : '信息修改成功', theme: 'text-success'}],
  ['updateFailed', {title : '提示', message : '信息修改失败', theme: 'text-danger'}],
  ['deleteSuccess', {title : '提示', message : '信息删除成功', theme: 'text-success'}],
  ['deleteFailed', {title : '提示', message : '信息删除失败', theme: 'text-danger'}],
  ['addSuccess', {title : '提示', message : '发布成功', theme: 'text-success'}],
  ['addFailed', {title : '提示', message : '发布失败', theme: 'text-danger'}],

  ['imagesLimit', {title : '提示', message : '图片不得超过十张，请重新选择!', theme: 'text-danger'}],
  ['imagesTooBig', {title : '提示', message : '图片过大，请重新选择!', theme: 'text-danger'}],
  ['tabIsNull', {title : '提示', message : '请选择所属标签!', theme: 'text-danger'}],
  ['inputIsEmpty', {title : '提示', message : '标题或者内容不能为空', theme: 'text-danger'}],
  ['filesIsEmpty', {title : '提示', message : '请选择文件', theme: 'text-danger'}],

  ['filesLimit', {title : '提示', message : '文件不得超过十个，请重新选择!', theme: 'text-danger'}],
  ['filesTooBig', {title : '提示', message : '文件过大，超过10M，请重新选择!', theme: 'text-danger'}],

  ['resetPasswordFailed', {title : '提示', message : '两次密码输入不一致!', theme: 'text-danger'}],
  ['resetPasswordCheck', {title : '提示', message : '密码需要在6-10位的数字和字母组合，不能包含特殊字符!', theme: 'text-danger'}],
  ['verifyCodeCheck', {title : '提示', message : '验证码有错！请查看最新邮件', theme: 'text-danger'}],
  ['resetPasswordSuccess', {title : '提示', message : '修改成功，请重新登录！', theme: 'text-success'}],
  
]);

export default NiceModal.create(({ type, value }) => {

  let notice = noticeMap.get(type);
  if (notice === undefined || notice === null)
    notice = noticeMap.get('systemError');

  // Use a hook to manage the modal state
  const modal = useModal();

  return (
    <Modal
      size="sm"
      centered={true}
      show={modal.visible}
      onHide={() => modal.hide()}
      onExit={() => modal.remove()}
      aria-labelledby="modal-sizes-title-sm"
    >
      <div className="my-modal">
        <Modal.Header closeButton>
          <Modal.Title as="p" id="modal-sizes-title-sm">
            <Icon>online_prediction</Icon> <span className="ms-2">{notice.title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={notice.theme}>
          {notice.message}
          <span className="ms-2"></span>
          {notice.theme === 'text-danger' ? <Icon>sentiment_dissatisfied</Icon> : <Icon>face</Icon>}
          <span className="ms-2 text-muted"> {value} </span>
        </Modal.Body>
      </div>
    </Modal>
  );
});