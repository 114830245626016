import '../App.css';
import { Icon } from '../components/basic'
import MainList from '../components/MainList';

import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { useEffect, useState, useTransition, createRef } from 'react';
import { useNavigate } from "react-router-dom";

import { convertTabToMap } from '../common/utils';
import http from '../common/http';

import NiceModal from '@ebay/nice-modal-react';
import MyModal from '../components/my-modal';

function Home() {

  const keyword = createRef();
  const [key, setKey] = useState('201');
  const [type, setType] = useState(1);
  const [appInfo, setAppInfo] = useState({ 'sites': [], 'notice1': null, 'notice2': null });
  const [isLoading, setLoading] = useState(true);
  /*  useTransition 执行返回一个数组。数组有两个状态值：
            1. isPending: 指处于过渡状态，正在加载中
            2. startTransition: 通过回调函数将状态更新包装起来告诉 React这是一个过渡任务，是一个低优先级的更新
  */
  const [isPending, startTransition] = useTransition();

  const navigate = useNavigate();
  const tab_btns = convertTabToMap();

  useEffect(() => {
    setLoading(true);
    var day = new Date().getDay();
    http.get('/api/web/info', { 'type': type, 'day': day }).then(v => {
      if (v.status !== 200) {
        setLoading(false);
        return;
      }
      setAppInfo(v.data);
    }).catch(function () {
      console.log("rejected the promise, something wrong happened");
    });
    setLoading(false);
  }, [type]);

  function search(event) {
    event.preventDefault();
    let keyValue = keyword.current.value;
    if (keyValue === '' || keyValue === null) {
      NiceModal.show(MyModal, { type : 'searchCheckError' });
    } else {
      startTransition(() => {
        navigate('/search/', {
          state: { 'keyword': keyValue, 'tabId': key },
          replace: true,
          shouldNavigate: true
        });
      })
    }
  }

  return (
    <>
      <div className={'header_nav_main ' + `header_nav_${key}`} id="header_nav">
        <Navbar expand="lg" className="opacity-50 nav_tab">
          <Navbar.Brand> Share </Navbar.Brand>
          <Tabs
            id="controlled-tabs"
            activeKey={key}
            onSelect={(key) => setKey(key)}
            className="mb-3 me-3 nav_tab_title"
          >
            <Tab eventKey="201" title="Tech">
            </Tab>
            <Tab eventKey="101" title="Fincial">
            </Tab>
            <Tab eventKey="301" title="Voice">
            </Tab>
            <Tab eventKey="401" title="Public" disabled>
              Tab content for {key}
            </Tab>
          </Tabs>
          <Navbar.Brand> with you </Navbar.Brand>
        </Navbar>

        <Form className="d-flex align-items-end my-4 nav_search" onSubmit={e => { search(e) }}>
          <Form.Control
            //type="search"
            placeholder='Search now...'
            className="opacity-25 nav_search_input"
            aria-label="Search"
            ref={keyword}
          />
        </Form>
        {isPending && <Spinner />}

        <div className="mb-2 tab_btn_group">
          {
            tab_btns && tab_btns.get(key).map((value) => (
              <Button key={value.t_id} variant="outline-light" onClick={() => { navigate(`/tabDetail/${value.t_id}`) }} > {value.t_name} </Button>
            ))
          }
        </div>
        <div className="notice_badget spotlight spotlight_inactive>">
          {appInfo.notice1 && <span><em>「 {appInfo.notice1.content} 」</em></span>}
        </div>
      </div>
      <div className="app_body text-center">
        <Row className="mt-3 mb-3">
          <Col sm={8} lg={10}>
            <div className="app_select">
              <Form.Select aria-label="Default select" onChange={(e) => { setType(e.target.value); setLoading(true); }}>
                <option value='1' default> Popular </option>
                <option value='2'> Latest </option>
              </Form.Select>
            </div>
          </Col>
          <Col sm={2} lg={1}>
            <a className="link" href={`/siteDetail/1`} target="_blank" rel="noreferrer"> -进站需知- </a>
          </Col>
          <Col sm={2} lg={1}>
            {
              appInfo.notice2 &&
              <OverlayTrigger placement="bottom" overlay={<Tooltip id="btn-tooltip-notify">{appInfo.notice2.content}</Tooltip>}>
                <div className="tooltip_notice"><Icon>record_voice_over</Icon> {appInfo.notice2.title} </div>
              </OverlayTrigger>
            }
          </Col>
        </Row>
        {isLoading && <Spinner animation="border" variant="secondry" />}
        <MainList arrays={appInfo.sites} type={type} />
      </div>
    </>
  );
}

export default Home;
